import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'
import { Link } from 'gatsby'
import { Card, Table, Spin } from 'antd'

import Layout from '../../../../components/layout'

const MobileContainer = styled('div')`
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 50px;
    width: 1024px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px;
    width: 100%;
  }
`

const ScoresContainer = styled.div`
  display: flex;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`

const StyledCard = styled(Card)`
  width: 100%;

  @media screen and (min-width: 1024px) {
    flex: 50% 0;
  }

  @media screen and (max-width: 1023px) {
    .ant-card-body {
      padding-left: 0%;
      padding-right: 0%;
    }
  }
`

class Scores extends Component {
  state = {
    series: [],
    loading: true,
  }

  componentDidMount = async () => {
    const res = await fetch('/.netlify/functions/fetch-scores', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ event: 'pro-league-2019' }),
    })

    this.setState({ series: await res.json(), loading: false })
  }

  render = () => {
    const { location } = this.props
    const { series, loading } = this.state
    return (
      <Layout location={location}>
        <MobileContainer>
          <h1>Scores</h1>
          <h4>Event: CWL Pro League</h4>
          {loading && (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Spin tip="Loading..." />
            </div>
          )}
          <ScoresContainer>
            {!loading &&
              series.map(({ id, score }) => {
                return (
                  <StyledCard
                    key={id}
                    title={
                      <div style={{ lineHeight: '36px' }}>
                        <Link
                          to={`/events/cwl-pro-league-2019/series/${id}`}
                          style={{ color: '#1890ff' }}
                        >
                          {`${score[0].team} v ${score[1].team} `}
                        </Link>
                        {score[0].wins !== 3 && score[1].wins !== 3 && (
                          <span style={{ color: 'red' }}>
                            <span
                              style={{ fontWeight: 'bold', fontSize: '18px' }}
                              role="img"
                              aria-label="live indicator"
                            >
                              •
                            </span>{' '}
                            LIVE
                          </span>
                        )}
                      </div>
                    }
                    extra={id.replace(/pro-w(\d+).*$/, 'Week $1')}
                    bordered={false}
                  >
                    <Link to={`/events/cwl-pro-league-2019/series/${id}`}>
                      <Table
                        pagination={false}
                        columns={[
                          {
                            title: '',
                            dataIndex: 'team',
                            key: 'team',
                            width: '30%',
                            onCell: () => {
                              return {
                                style: {
                                  whiteSpace: 'nowrap',
                                  maxWidth: 60,
                                },
                              }
                            },
                            render: text => (
                              <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                {text}
                              </div>
                            ),
                          },
                          {
                            title: 'HP',
                            dataIndex: 'scores',
                            key: 'HP1',
                            onHeaderCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            onCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            render: scores => (
                              <span style={scores[0] === 250 ? { fontWeight: 'bold' } : {}}>
                                {scores[0] === 0 ? 0 : scores[0] || '-'}
                              </span>
                            ),
                          },
                          {
                            title: 'SND',
                            dataIndex: 'scores',
                            key: 'SND1',
                            onHeaderCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            onCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            render: scores => (
                              <span style={scores[1] === 6 ? { fontWeight: 'bold' } : {}}>
                                {scores[1] === 0 ? 0 : scores[1] || '-'}
                              </span>
                            ),
                          },
                          {
                            title: 'CTL',
                            dataIndex: 'scores',
                            key: 'CTL',
                            onHeaderCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            onCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            render: scores => (
                              <span style={scores[2] === 3 ? { fontWeight: 'bold' } : {}}>
                                {scores[2] === 0 ? 0 : scores[2] || '-'}
                              </span>
                            ),
                          },
                          {
                            title: 'HP',
                            dataIndex: 'scores',
                            key: 'HP2',
                            onHeaderCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            onCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            render: scores => (
                              <span style={scores[3] === 250 ? { fontWeight: 'bold' } : {}}>
                                {scores[3] === 0 ? 0 : scores[3] || '-'}
                              </span>
                            ),
                          },
                          {
                            title: 'SND',
                            dataIndex: 'scores',
                            key: 'SND2',
                            onHeaderCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            onCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            render: scores => (
                              <span style={scores[4] === 6 ? { fontWeight: 'bold' } : {}}>
                                {scores[4] === 0 ? 0 : scores[4] || '-'}
                              </span>
                            ),
                          },
                          {
                            title: 'Series',
                            dataIndex: 'wins',
                            key: 'series',
                            onHeaderCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            onCell: () => {
                              return {
                                style: {
                                  textAlign: 'center',
                                },
                              }
                            },
                            render: (wins, row) => (
                              <span
                                style={
                                  row.wins === 3 ? { color: '#1890ff', fontWeight: 'bold' } : {}
                                }
                              >
                                {wins}
                              </span>
                            ),
                          },
                        ]}
                        dataSource={score}
                        size="small"
                      />
                    </Link>
                  </StyledCard>
                )
              })}
          </ScoresContainer>
        </MobileContainer>
      </Layout>
    )
  }
}

Scores.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default Scores
